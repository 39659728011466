import { Button, Modal } from "react-bootstrap";
import { IBase } from "../../interfaces/IBase";
import { LINKS } from "../../config";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase | null;
}

export const ModalResetBase = ({ onHide, isOpen, base }: Props) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        axios.post(`${LINKS.API}/bases/reset/${base?.id}`).then((response) => {
            if(response.data.status === 200) {
                onHide();
                toast.success(response.data.message);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        });
    };

    return (
        base && (
            <Modal show={isOpen} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Resetar base</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que deseja resetar a base <strong>{base.name}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmit}>Resetar</Button>
                    <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        )

    )
}