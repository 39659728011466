import { Routes, Route, BrowserRouter } from "react-router-dom"
import { Layout } from "./components/Layout"
import { Login } from "./pages/Login/Login"
import { Producao } from "./pages/Producao/Producao"
import { RequireAuth } from "./features/auth/RequireAuth"
import { Comunicados } from "./pages/Comunicados/Comunicados"
import { RequireAdmin } from "./features/auth/RequireAdmin"
import { NovoComunicado } from "./pages/Comunicados/NovoComunicado"
import { EditarComunicado } from "./pages/Comunicados/EditarComunicado"
import { NotFound } from "./pages/NotFound"
import { RequireOperacional } from "./features/auth/RequireOperacional"
import { Automacoes } from "./pages/Automacoes/Automacoes"
import { NovaAutomacao } from "./pages/Automacoes/NovaAutomacao"
import { EditarAutomacao } from "./pages/Automacoes/EditarAutomacao"
import { Acessos } from "./pages/Acessos/Acessos"
import { Perfil } from "./pages/Perfil/Perfil"
import { Users } from "./pages/Users/Users"
import { Tutorial } from "./pages/Tutorial/Tutorial"
import { Class } from "./pages/Tutorial/Class"
import { ManageTutorials } from "./pages/Tutorial/ManageTutorials"
import { ManageClasses } from "./pages/Tutorial/ManageClasses"
import { NewTutorial } from "./pages/Tutorial/NewTutorial"
import { NewClass } from "./pages/Tutorial/NewClass"
import { Banks } from "./pages/Banks/Banks"
import { NewBank } from "./pages/Banks/NewBank"
import { Teams } from "./pages/Teams/Teams"
import { Documentos } from "./pages/Perfil/Documentos"
import { Leads } from "./pages/Leads/Leads"
import { DevelopmentConfig } from "./pages/DevelopmentConfig/DevelopmentConfig";
import { Bases } from "./pages/Bases/Bases"
import { Stock } from "./pages/Stock/Stock"
import { Items } from "./pages/Stock/Items"
import { Customers } from "./pages/Customers/Customers"
import { Customer } from "./pages/Customers/Customer"
import { Base } from "./pages/Bases/Base"
import { DailyRecords } from "./pages/DailyRecords/DailyRecords"
import { AllDailyRecords } from "./pages/DailyRecords/AllDailyRecords"
import { Operations } from "./pages/Site/Operations/Operations"
import { Evidences } from "./pages/Evidences/Evidences"
import { Logs } from "./pages/Logs/Logs"
import { ManageBanks } from "./pages/Banks/ManageBanks"
import { ManageNotifications } from "./pages/ManageNotifications/ManageNotifications"

export const Router = () => {
  return (
    <BrowserRouter>

      <Routes>

        {/* PRIVATE ROUTES */}
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAuth />}  >
            <Route path="/producao/:mes?/:ano?/:equipe?" element={<Producao />} />
            <Route path="/comunicados" element={<Comunicados />} />
            <Route path="/acessos" element={<Acessos />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/perfil/:perfil_id" element={<Perfil />} />
            <Route path="/tutoriais/:slug" element={<Tutorial />} />
            <Route path="/tutoriais/:slugTutorial/:slugAula" element={<Class />} />
            <Route path="/bancos" element={<Banks />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/relatorios-diarios/:seller_id" element={<DailyRecords />} />
            <Route path="/bases" element={<Bases />} />
            <Route path="/bases/:base_id" element={<Base />} />

            <Route path="/evidencias" element={<Evidences />} />

            <Route element={<RequireAdmin />} >
              <Route path="/comunicados/editar/:comunicado" element={<EditarComunicado />} />
              <Route path="/comunicados/novo" element={<NovoComunicado />} />

              <Route path="/automacoes/novo" element={<NovaAutomacao />} />
              <Route path="/automacoes/editar/:automacao" element={<EditarAutomacao />} />

              <Route path="/usuarios" element={<Users />} />

              {/* <Route path="/site/servicos" element={<Servicos />} />
              <Route path="/site/servicos/:servico_slug" element={<Servico />} /> */}
              <Route path="/site/operacoes" element={<Operations />} />


              <Route path="gerenciar-tutoriais" element={<ManageTutorials />} />
              <Route path="gerenciar-bancos" element={<ManageBanks />} />
              <Route path="gerenciar-notificacoes" element={<ManageNotifications />} />

              <Route path="tutoriais/novo" element={<NewTutorial />} />
              <Route path=":slugTutorial/aulas/novo" element={<NewClass />} />
              <Route path="gerenciar-tutoriais/:slugTutorial/aulas" element={<ManageClasses />} />
              <Route path="/bancos/novo" element={<NewBank />} />
              <Route path="/equipes" element={<Teams />} />
              <Route path="/estoque" element={<Stock />} />
              <Route path="/estoque/:id" element={<Items />} />

              <Route path="/perfil/:perfil_id/documentos" element={<Documentos />} />

              <Route path="/logs" element={<Logs />} />

              <Route path="/developer" element={<DevelopmentConfig />} />

            </Route>

            <Route element={<RequireOperacional />} >
              <Route path="/automacoes" element={<Automacoes />} />
              <Route path="/clientes" element={<Customers />} />
              <Route path="/clientes/:customer_id" element={<Customer />} />
              <Route path="/relatorios-diarios" element={<AllDailyRecords />} />

            </Route>

          </Route>
        </Route>

        {/* PUBLIC ROUTES */}
        <Route path="/" index element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  )
}