import { Button, Form } from "react-bootstrap";
import styles from "../../styles/Tutorial.module.scss";
import { useEffect, useState } from "react";
import { IClass, ITutorial } from "../../interfaces/ITutorial";
import axios from "axios";
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../NotFound";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

export const NewClass = () => {

    const [data, setData] = useState({} as IClass);
    const [imgFile, setImgFile] = useState<File | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);
    const { slugTutorial } = useParams();
    const [tutorial, setTutorial] = useState<ITutorial | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const formData = new FormData();
        formData.append('slug', slugTutorial || "");
        axios.get(`${LINKS.API}/tutorials` + (slugTutorial ? `/${slugTutorial}` : ""))
            .then(response => {
                if (response.data.status === 200) {
                    setTutorial(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                }
            }).catch(error => {
                console.error("Error fetching tutorials:", error);

            })
    }, [currentUser.token, slugTutorial, dispatch, navigate]);

    if (!slugTutorial) return <NotFound />;
    const handleSubmit = (e: React.FormEvent) => {
        // Implementar
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description ?? "");
        formData.append('slug', data.slug);
        formData.append('content', data.content ?? "");
        // formData.append('order', data.ordering.toString());
        formData.append('tutorial_id', tutorial?.id.toString() ?? "");
        if (file) formData.append('file', file);
        if (imgFile) formData.append('image', imgFile);

        if (!data.name || !data.slug) {
            toast.error('Preencha todos os campos obrigatórios');
            return;
        }

        axios.post(`${LINKS.API}/tutorials/classes`, formData, { headers: { 'Content-Type': "multipart/form-data" } })
            .then(response => {
                if (response.data.status === 201) {
                    toast.success(response.data.message);
                    navigate(`/gerenciar-tutoriais/${tutorial?.slug}/aulas`);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error("Error creating aula:", error);
                toast.error("Error creating aula. Please try again later.");
            });

    }

    return (
        <div className="container">
            <h4 className='titulo'>Nova Aula - {tutorial?.name}</h4>

            <Form onSubmit={handleSubmit} className={styles.form} encType="multipart/form-data">

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="titulo">Título *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Título da aula"
                        value={data.name}
                        name="titulo"
                        onChange={(event) => {
                            setData({
                                ...data,
                                name: event.target.value
                            })
                        }} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="descricao">Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Descrição da aula"
                        value={data.description}
                        rows={3}
                        name="descricao"
                        onChange={(event) => {
                            setData({
                                ...data,
                                description: event.target.value
                            })
                        }} />
                </Form.Group>

                {/* SLUG (link) */}
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="link">Link *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Link da aula"
                        value={data.slug}
                        name="link"
                        onChange={(event) => {
                            setData({
                                ...data,
                                slug: event.target.value
                            })
                        }} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="imagem">Imagem</Form.Label>
                    <input
                        type="file"
                        className="form-control"
                        id="imagem"
                        name="imagem"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                setImgFile(e.target.files[0]);
                            }
                        }}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="file">Arquivo (vídeo, pdf, áudio, foto, etc)</Form.Label>
                    <input
                        type="file"
                        className="form-control"
                        id="file"
                        name="file"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                setFile(e.target.files[0]);
                            }
                        }}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="conteudo">Conteúdo</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Conteúdo da aula"
                        value={data.content}
                        rows={5}
                        name="conteudo"
                        onChange={(event) => {
                            setData({
                                ...data,
                                content: event.target.value
                            })
                        }} />
                </Form.Group>

                {
                    isLoading
                        ?
                        <Button variant="primary" disabled>Criando...</Button>
                        :
                        <Button className={styles.submit_novo} type='submit'>Criar Aula</Button>
                }
            </Form>

        </div>
    );
}