import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { IUser } from "../../interfaces/IUser";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IAcesso } from "../../interfaces/IAcesso";

interface Props {
    show: boolean;
    onHide: () => void;
    handleNovoAcesso: (newAcess: IAcesso) => void;
}

interface FormData {
    link: string;
    banco: string;
    promotora: string;
    login: string;
    password: string;
}

export const ModalNovoAcesso: React.FC<Props> = ({ show, onHide, handleNovoAcesso }: Props) => {
    const currentUser = useSelector(selectCurrentUser);
    const [formData, setFormData] = useState<FormData>({
        link: "",
        banco: "",
        promotora: "",
        login: "",
        password: ""
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [usuarios, setUsuarios] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<number | null>(currentUser.id);
    const requiredFields: Record<keyof FormData, boolean> = {
        link: false,
        banco: true,
        promotora: false,
        login: true,
        password: true
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const requiredFieldsFilled = Object.entries(requiredFields).every(([fieldName, isRequired]) => {
            return !isRequired || !!formData[fieldName as keyof FormData];
        });

        if (!requiredFieldsFilled) {
            toast.error("Por favor, preencha todos os campos obrigatórios.");
            return;
        }

        try {
            const response = await axios.post(`${LINKS.API}/access`, { ...formData, user_id: selectedUser });
            if (response.data.status === 201) {
                toast.success(response.data.message);
                handleNovoAcesso(response.data.data as IAcesso);
                setFormData({
                    link: "",
                    banco: "",
                    promotora: "",
                    login: "",
                    password: ""
                })
                onHide();

            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error("Erro ao adicionar acesso");
            }
        } catch (error) {
            console.error(error);
            toast.error("Erro ao adicionar acesso");
        }
    };

    useEffect(() => {
        if (show) {
            if (currentUser.type !== "admin") {
                setSelectedUser(currentUser.id);
                return;
            };
            axios.get(`${LINKS.API}/users/branch`).then(response => {
                if (response.data.status === 200) {
                    setUsuarios(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error(error);
                toast.error("Erro ao carregar usuários");
            });
        }
    }, [currentUser, dispatch, navigate, show]);

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Novo Acesso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {
                        currentUser.type === "admin" &&
                        <Form.Group controlId="user">
                            <Form.Label>Usuário</Form.Label>
                            <Form.Control onChange={(e) => {
                                setSelectedUser(parseInt(e.target.value));
                            }} as="select" name="user">
                                {usuarios.map((usuario) => (
                                    <option selected={currentUser.id === usuario.id ? true : false} key={usuario.id} value={usuario.id}>
                                        {usuario.name.trim()} {usuario.last_name ? usuario.last_name.trim() : ""}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    }
                    <Form.Group className="mt-3" controlId="link">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o link"
                            name="link"
                            value={formData.link}
                            onChange={handleInputChange}
                            required={requiredFields.link}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="banco">
                        <Form.Label>Banco</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o banco"
                            name="banco"
                            value={formData.banco}
                            onChange={handleInputChange}
                            required={requiredFields.banco}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="promotora">
                        <Form.Label>Promotora</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite a promotora"
                            name="promotora"
                            value={formData.promotora}
                            onChange={handleInputChange}
                            required={requiredFields.promotora}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="login">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o login"
                            name="login"
                            value={formData.login}
                            onChange={handleInputChange}
                            required={requiredFields.login}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="password">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite a senha"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required={requiredFields.password}
                        />
                    </Form.Group>
                    <Button className="mt-3" variant="primary" type="submit">
                        Adicionar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

