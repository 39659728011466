import { useEffect, useState } from "react";
import { IBase } from "../../interfaces/IBase";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LINKS } from "../../config";
import { Loader } from "../../components/Loader";
import styles from "../../styles/Bases.module.scss";
import classNames from "classnames";
import { ViewBase } from "../../components/Bases/ViewBase";
import { Button } from "react-bootstrap";
import { FiCheck, FiEdit, FiSearch } from "react-icons/fi";
import { IProposal } from "../../interfaces/IProposal";
import { FaCloudDownloadAlt, FaEye } from "react-icons/fa";
import { VisualizarProposta } from "../../components/Customers/VisualizarProposta";
import { PieChart } from "../../components/Graficos/Pizza";
import { IoCloseSharp, IoReload } from "react-icons/io5";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ModalUpdate } from "../../components/Bases/ModalUpdate";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ModalDownload } from "../../components/Bases/ModalDownload";
import { RiLinkUnlinkM } from "react-icons/ri";
import { ModalPermission } from "../../components/Bases/ModalPermission";
import { ModalEditarBase } from "../../components/Bases/ModalEditarBase";
import { ModalResetBase } from "../../components/Bases/ModalResetBase";
// import { ModalSwitchBaseProposal } from "../../components/Bases/ModalSwitchBaseProposal";

export const Base = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [base, setBase] = useState<IBase | null>(null);
    const { base_id } = useParams();

    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [proposals, setProposals] = useState<IProposal[]>([]);
    const [isViewBaseOpen, setIsViewBaseOpen] = useState(false);
    const [viewProposalOpen, setViewProposalOpen] = useState(false);
    const [viewProposal, setViewProposal] = useState<IProposal>();
    const [remakeRequest, setRemakeRequest] = useState(false);
    const [viewProposals, setViewProposals] = useState<boolean>(false);
    const [openModalCheckBase, setOpenModalCheckBase] = useState(false);
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [modalPermissionsOpen, setModalPermissionsOpen] = useState(false);
    const [openModalEditBase, setOpenModalEditBase] = useState(false);
    const [modalResetBaseOpen, setModalResetBaseOpen] = useState(false);
    // const [proposalToSwitch, setProposalToSwitch] = useState<IProposal | null>(null);
    // const [openModalSwitchBaseProposal, setOpenModalSwitchBaseProposal] = useState(false);

    useEffect(() => {
        const fetchBase = async () => {
            try {
                const response = await axios.get(`${LINKS.API}/bases/data/${base_id}`);

                if (response.data.status === 200) {
                    setBase(response.data.data.base);
                    setProposals(response.data.data.proposals);
                    setIsLoading(false);

                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    console.error("Erro ao buscar base.", response.data.message);
                }
            } catch (error) {
                console.error("Erro ao buscar base.", error);
            }
        };

        if (base_id) {
            fetchBase();
        }
    }, [base_id, remakeRequest, currentUser, dispatch, navigate]);

    return (<>
        <Link to={`/bases`}>
            <IoMdArrowRoundBack size={40} className="mt-4 mx-5" />
        </Link>
        <div className="container">
            {isLoading ? (
                <Loader color="blue" size="150px" />
            ) : (
                base && (<>
                    <section className={styles.base_infos}>
                        <table className={classNames(styles.base_infos_table, "table")}>
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td>{base.id}</td>
                                </tr>
                                <tr>
                                    <td><strong>Nome</strong></td>
                                    <td>{base.name}</td>
                                </tr>
                                {
                                    base.seller &&
                                    <tr>
                                        <td><strong>Vendedor</strong></td>

                                        <td>
                                            <Link
                                                to={`/perfil/${base.seller_id}`}
                                            >
                                                {base.seller}
                                            </Link>
                                        </td>
                                    </tr>
                                }

                                <tr>
                                    <td><strong>Enviado em</strong></td>
                                    <td>{base.sent_at}</td>
                                </tr>
                                {
                                    base.distributed === 1 ?
                                        <tr>
                                            <td><strong>Distribuida em</strong></td>
                                            <td>{base.distributed_at}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <td>
                                                <strong>Distribuida</strong>
                                            </td>
                                            <td>
                                                <IoCloseSharp color="red" />
                                            </td>
                                        </tr>
                                }
                                <tr>
                                    <td><strong>Quantidade de Registros</strong></td>
                                    <td>{base.total_customers}</td>
                                </tr>
                                <tr>
                                    <td><strong>Atendimentos avaliados</strong></td>
                                    <td>{base.evaluated_qty} ({base.evaluated_percentage.replaceAll(".", ",")}%)</td>
                                </tr>
                                <tr>
                                    <td><strong>Média de margem</strong></td>
                                    <td>{base.margin_mean.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                                <tr>
                                    <td><strong>Última verificação</strong></td>
                                    <td>{base.last_check_at}</td>
                                </tr>
                                <tr>
                                    <td><strong>Clientes marcados</strong></td>
                                    <td>{base.checked_qty} ({((base.checked_qty / base.total_customers) * 100).toFixed(1)}%)</td>
                                </tr>
                            </thead>
                        </table>
                        <div className={styles.view_base_buttons}>
                            <Button
                                variant="outline-secondary"
                                onClick={() => setIsViewBaseOpen(true)}
                            >
                                Visualizar <FiSearch />
                            </Button>
                            {
                                currentUser.type === "admin" &&
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setOpenModalEditBase(true);
                                    }}
                                >
                                    Editar <FiEdit />
                                </Button>
                            }
                            {
                                (currentUser.type === "admin" || currentUser.type === "operacional") &&
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setShowModalDownload(true);
                                    }}
                                >
                                    Baixar <FaCloudDownloadAlt />
                                </Button>
                            }
                            {
                                base.distributed === 1 && (currentUser.type === "admin" || currentUser.type === "operacional") &&
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setOpenModalCheckBase(true)}
                                >
                                    Check Base <FiCheck />
                                </Button>
                            }
                            {
                                currentUser.type === "admin" &&
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setModalPermissionsOpen(true);
                                    }}
                                >
                                    Permissões <RiLinkUnlinkM />

                                </Button>

                            }
                            {
                                currentUser.type === "admin" &&
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setModalResetBaseOpen(true);
                                    }}
                                >
                                    Resetar <IoReload />
                                </Button>
                            }
                            <Button
                                variant="outline-secondary"
                                onClick={() => setRemakeRequest(!remakeRequest)}
                                title="Atualizar informações da tela"
                            >
                                <IoReload />
                            </Button>
                        </div>

                    </section>

                    <section className={styles.proposals}>
                        <h4>Propostas
                            <Button variant="a" onClick={() => setViewProposals(!viewProposals)}>
                                {viewProposals ? <BsEye /> : <BsEyeSlash />}
                            </Button></h4>

                        {
                            proposals.length > 0 ? (

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Data da proposta</th>
                                            <th>Contrato</th>
                                            <th>Proposta</th>
                                            <th>Banco</th>
                                            <th>Valor de parcela</th>
                                            <th>Valor liberado</th>
                                            <th>Visualizar</th>
                                            {/* <th>Trocar</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            viewProposals ? (
                                                proposals.map((proposal, index) => (
                                                    <tr key={index}>
                                                        <td>{proposal.id}</td>
                                                        <td>{proposal.proposal_date}</td>
                                                        <td>{proposal.contract}</td>
                                                        <td>{proposal.proposal}</td>
                                                        <td>{proposal.bank}</td>
                                                        <td>R$ {proposal.parcel_value.toLocaleString("pt-BR")}</td>
                                                        <td>R$ {proposal.released_value.toLocaleString("pt-BR")}</td>
                                                        <td className={styles.details}>
                                                            <FaEye
                                                                cursor={"pointer"}
                                                                onClick={() => {
                                                                    setViewProposal(proposal);
                                                                    setViewProposalOpen(true);
                                                                }}
                                                            />
                                                        </td>
                                                        {/* <td className={styles.details}>
                                                            <HiSwitchVertical
                                                                cursor={"pointer"}
                                                                onClick={() => {
                                                                    setProposalToSwitch(proposal);
                                                                    setOpenModalSwitchBaseProposal(true);
                                                                }}
                                                                title="Trocar base"
                                                            />
                                                        </td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>Propostas ocultadas.</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    {/* <td></td> */}
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>

                            ) : "Nenhuma proposta encontrada."
                        }
                    </section>

                    <section className={styles.graph_section}>
                        <h4>Gráficos</h4>
                        <div className={styles.graphs_div}>
                            <div className={styles.graph_div}>
                                <h5>Aproveitamento ({base.closed_proposals_percentage.replaceAll(".", ",")}%)</h5>
                                {/* Gráfico que mostre quantas propostas foram fechadas daquela base */}
                                <PieChart
                                    labels={['Fechadas', 'Não fechadas', 'Em andamento']}
                                    datasets={[{
                                        label: 'Aproveitamento',
                                        data: [base.closed_proposals_qty, base.total_customers - base.closed_proposals_qty, 0],
                                        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56']
                                    }]}
                                />

                            </div>
                            <div className={styles.graph_div}>
                                <h5>Margens Perdidas ({base.lose_margin_percentage.replaceAll(".", ",")}%)</h5>
                                {/* Gráfico que mostre a quantidade de clientes que perderam margem daquela base */}
                                <PieChart
                                    labels={['Margens perdidas', 'Não perdidas']}
                                    datasets={[{
                                        label: 'Margens perdidas',
                                        data: [base.lose_margin_qty, base.total_customers - base.lose_margin_qty],
                                        backgroundColor: ['#36A2EB', '#FF6384']
                                    }]}
                                />

                            </div>
                            <div className={styles.graph_div}>
                                <h5>Clientes contatados ({base.contacted_percentage.replaceAll(".", ",")}%)</h5>
                                {/* Gráfico que mostre a quantidade de clientes que foram contatados */}
                                <PieChart
                                    labels={['Clientes contatados', 'Não contatados']}
                                    datasets={[{
                                        label: 'Clientes contatados',
                                        data: [base.contacted_qty, base.total_customers - base.contacted_qty],
                                        backgroundColor: ['#36A2EB', '#FF6384']
                                    }]}
                                />
                            </div>
                        </div>

                    </section>

                    <ViewBase base={base} isOpen={isViewBaseOpen} onHide={() => setIsViewBaseOpen(false)} />
                    {proposals.length > 0 && <VisualizarProposta onHide={() => setViewProposalOpen(false)} isOpen={viewProposalOpen} proposal={viewProposal || proposals[0]} />}

                    {
                        base.distributed === 1 &&
                        <ModalUpdate editBase={() => { }} isOpen={openModalCheckBase} onHide={() => setOpenModalCheckBase(false)} baseToUpdate={base} />
                    }
                    <ModalDownload
                        isOpen={showModalDownload}
                        onHide={() => setShowModalDownload(false)}
                        base={base}
                    />
                    <ModalEditarBase
                        isOpen={openModalEditBase}
                        onHide={() => setOpenModalEditBase(false)}
                        base={base}
                        setBase={(base: IBase) => {
                            setBase(base);
                            setOpenModalEditBase(false);
                        }}
                    />
                    {/* <ModalSwitchBaseProposal
                        setProposals={setProposals}
                        isOpen={openModalSwitchBaseProposal}
                        onHide={() => setOpenModalSwitchBaseProposal(false)}
                        proposal={proposalToSwitch}
                    /> */}
                    <ModalResetBase
                        isOpen={modalResetBaseOpen}
                        onHide={() => setModalResetBaseOpen(false)}
                        base={base}
                    />
                </>)

            )}
            <ModalPermission isOpen={modalPermissionsOpen} onHide={() => setModalPermissionsOpen(false)} base={base} />
        </div>
    </>
    );
}