import { Button, Modal } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    show: boolean;
    onHide: () => void;
    user: IUser;
    blockUser: (user: IUser) => void;
}

export const ModalBlockUser = ({ show, onHide, user, blockUser }: Props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleBlock = (id: number) => {

        const action = user.ativo === 1 ? 0 : 1;

        axios.put(`${LINKS.API}/users/${action === 1 ? 'un' : ''}block/${id}`)
            .then(response => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    blockUser(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error(error);
                toast.error("Erro ao bloquear/desbloquear usuário");
            });
        onHide();
    }

    if (!user) return null;

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Bloquear Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que deseja {user.ativo === 1 ? "bloquear" : "desbloquear"} o usuário <strong>{`${user.name.trim()} ${user.last_name.trim()}`}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={() => handleBlock(user.id)}>
                    {user.ativo === 1 ? "Bloquear" : "Desbloquear"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};