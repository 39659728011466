import { Modal } from "react-bootstrap"
import { IClass } from "../../interfaces/ITutorial"

import { useNavigate } from "react-router-dom"

interface Props {
    show: boolean,
    onHide: () => void,
    class_: IClass
}

export const ModalClass = ({ show, onHide, class_ }: Props) => {
    const navigate = useNavigate();
    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{class_.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {class_.description}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => navigate(class_.slug)} className="btn btn-primary">Entrar</button>
                <button onClick={onHide} className="btn btn-secondary">Fechar</button>
            </Modal.Footer>
        </Modal>
    )
}