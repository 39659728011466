import { FaEdit } from "react-icons/fa";
import { IUser } from "../../interfaces/IUser";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import classNames from "classnames";
import styles from "../../styles/Users.module.scss";
import { Link } from "react-router-dom";

interface Props {
    user: IUser;
    editUser: (user: IUser) => void;
    handleUpdateModal: (user: IUser) => void;
    handleOpenBlockModal: () => void;
}

export const User = ({ user, handleUpdateModal, handleOpenBlockModal }: Props) => {

    const handleEdit = () => {
        handleUpdateModal(user);
    };

    return (
        <tr className={user.ativo === 0 ? styles.inativo : ""}>
            <th scope="row">{user.id}</th>
            <td>
                <Link to={`/perfil/${user.id}`}>
                    {`${user.name.trim()} ${user.last_name ? user.last_name.trim() : ''}`}
                </Link>
            </td>
            <td>{user.email}</td>
            <td>{user.type}</td>
            {
                user.type !== "robot"
                    ? (
                        <td title="Editar Acesso" className={classNames("text-center", styles.botoes)}>
                            <FaEdit size={20} color="black" cursor="pointer" onClick={handleEdit} />
                        </td>
                    )
                    :
                    (<td></td>)
            }

            {
                user.type !== "robot" ? (

                    user.ativo === 1
                        ?
                        (
                            <td title="Bloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaLock size={20} color="black" cursor="pointer" onClick={handleOpenBlockModal} />
                            </td>
                        )
                        :
                        (
                            <td title="Desbloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaUnlock size={20} color="black" cursor="pointer" onClick={handleOpenBlockModal} />
                            </td>
                        )

                ) : (<td></td>)
            }
            {/* <td>{user.photo}</td> */}
            {/* <td>{user.status}</td>
            <td>{user.created_at}</td>
            <td>{user.updated_at}</td> */}

        </tr>
    )
}
